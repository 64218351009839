import React from "react"
import ContextProvider from "../context"
import classes from "./css/Cirugias.module.css"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/Banner/Banner"

const Enfermedades = () => {
  const data = [
    "Lesiones y Enfermedades de la Rodilla y Cadera",
    "Patología de la Columna Vertebral (Ciática)",
    "Lesiones del Cartílago de la Rodilla y Artrosis",
    "Lesión del Manguito Rotador de Hombro",
    "Ruptura del Ligamento Cruzado Anterior de Rodilla",
    "Ruptura de Meniscos de Rodilla",
    "Fracturas",
    "Tendinitis",
    "Artritis y Enfermedades Reumáticas",
    "Cirugía Mini Invasiva",
    "Cirugía Percutanea de Hallux Valgus (Juanete)",
    "Revisiones Protésicas",
    "Reemplazos articulares (Artroplastía)",
    "Infiltraciones y Tratamientos Biológicos",
    "Síndrome de Tunel Carpeano",
    "Patología de Miembro Superior e Inferior",
    "Lesiones Deportivas",
  ]

  return (
    <ContextProvider>
      <Layout>
        <SEO title="Enfermedades Tratadas" />
        <div className={classes.root}>
          <Banner content="Enfermedades Tratadas" />
          <p
            className={classes.subtitle}
            style={{ paddingTop: "2rem", fontSize: "1.4rem" }}
          >
            A continuación se presenta un listado de las enfermedades más
            comunmente tratadas:
          </p>
          <ul className={classes.list}>
            {data.map(item => {
              return <li>{item}</li>
            })}
          </ul>
          <p className={classes.subtitle} style={{ fontSize: "1.4rem" }}>
            Y muchas más, consulte con el especialista para más información.
          </p>
        </div>
      </Layout>
    </ContextProvider>
  )
}

export default Enfermedades
